@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/
@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_0_0.woff2") format("woff2"), url("webfonts/372607_0_0.woff") format("woff"), url("webfonts/372607_0_0.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_1_0.woff2") format("woff2"), url("webfonts/372607_1_0.woff") format("woff"), url("webfonts/372607_1_0.ttf") format("truetype"); }

.content {
  display: none; }
  @media (min-width: 480px) {
    .content {
      position: relative;
      height: calc(100vh - 96px - 42px - 24px);
      display: flex;
      z-index: 100;
      flex-wrap: wrap;
      align-content: flex-start; } }
  .content h1 {
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 0;
    margin: 0 0 0.5em;
    position: relative;
    width: 100%; }
  .content p {
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 0;
    margin: 0 auto; }

.link {
  width: 40%;
  margin: 0 2em 0 2em;
  float: left;
  text-decoration: none;
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  flex-direction: column; }
  .link .trophy {
    display: inline-block;
    width: 100%;
    min-height: 30vh;
    background: url(img/trophy.png) no-repeat left center;
    background-size: contain; }
  .link .chat {
    display: inline-block;
    width: 100%;
    min-height: 30vh;
    background: url(img/chat.png) no-repeat left center;
    background-size: contain; }
  .link .copy {
    font-family: "FrutigerLTPro-LightCn", sans-serif;
    color: #666;
    display: block;
    font-size: 1.2em;
    line-height: 1.4em;
    max-width: 320px;
    margin: 0.5em 0 0; }
    .link .copy > span {
      transform: translateX(0);
      margin: 0 0 -1px 0.5em; }
  .link:hover .copy > span {
    animation: bounce 0.25s ease-out infinite alternate; }

.next {
  margin: 3em 0 0;
  display: inline-block;
  width: auto;
  text-align: right;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0; }
  .next > span {
    transform: translateX(0); }
  .next:hover > span {
    animation: bounce 0.25s ease-out infinite alternate; }

@keyframes bounce {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(75%); } }

.enter .content {
  animation: fadein 2s ease-in; }
  .enter .content h1 {
    animation: slidein 2s ease-in; }
  .enter .content p {
    animation: slidein 2s ease-in; }
  .enter .content .link {
    animation: slidein 2s ease-in; }
    .enter .content .link .trophy {
      animation: scalein 2s ease-in; }
    .enter .content .link .chat {
      animation: scalein 2s ease-in; }

.exit .content {
  animation: fadeout 2s ease-out forwards; }
  .exit .content h1 {
    animation: slideout 2s ease-out forwards; }
  .exit .content p {
    animation: slideout 2s ease-out forwards; }
  .exit .content .link {
    animation: slideout 2s ease-out forwards; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes slidein {
  from {
    transform: translateY(-30px); }
  to {
    transform: translateY(0); } }

@keyframes slideout {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-30px); } }

@keyframes scalein {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }
