@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/
@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_0_0.woff2") format("woff2"), url("webfonts/372607_0_0.woff") format("woff"), url("webfonts/372607_0_0.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_1_0.woff2") format("woff2"), url("webfonts/372607_1_0.woff") format("woff"), url("webfonts/372607_1_0.ttf") format("truetype"); }

.content {
  display: none; }
  @media (min-width: 480px) {
    .content {
      position: relative;
      height: calc(100vh - 96px - 42px - 24px);
      display: flex;
      z-index: 100;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow-y: scroll; } }
  .content p {
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 0;
    width: 100%; }
    .content p a {
      font-size: 1.1em; }

.next {
  margin: 3em 0.25em 0;
  display: inline-block;
  width: auto;
  text-align: right;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0; }
  .next > span {
    transform: translateX(0); }
  .next:hover > span {
    animation: bounce 0.25s ease-out infinite alternate; }

@keyframes bounce {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(75%); } }
